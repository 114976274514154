import React from "react";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import HeroSection from "../../components/HeroSection/HeroSection";
import HeroCaption from "../../components/HeroCaption/HeroCaption";
import LibraryInfoResponsive from "../../components/LibraryInfoResponsive/LibraryInfoResponsive";
import PageSection from "../../components/PageSection/PageSection";
import ResponsivePageHeader from "../../components/ResponsivePageHeader/ResponsivePageHeader";
import { Col, Row } from "react-bootstrap";
import { AiFillFilePdf } from "react-icons/ai";
import CustomIcon from "../../components/CustomIcon/CustomIcon";
import FacultyResponsive from "../../components/FacultyResponsive/FacultyResponsive";
import CoursesResponsive from "../../components/CoursesResponsive/CoursesResponsive";
import JornalPublicationResponsive from "../../components/JornalPublicationResponsive/JornalPublicationResponsive";

const LeadershipArchiveResponsive = () => {
  return (
    <PageSection type="lg" bgClass="page-background-1">
      <ResponsivePageHeader title="Archives" />
      <Row className="justify-content-center">
        <Col md="3">
          <a
            href={
              process.env.PUBLIC_URL +
              "/Statutes & Ordinances Pertaining to Academics.pdf"
            }
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              Statutes & Ordinances Pertaining to Academics
            </span>
          </a>
        </Col>

        <Col md="3">
          <a
            href={
              process.env.PUBLIC_URL + "/Institutional Development Plan.pdf"
            }
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">Institutional Development Plan</span>
          </a>
        </Col>

        <Col md="3">
          <a
            href={
              process.env.PUBLIC_URL +
              "/Constitutent-Affiliated College (BPUT Affiliation Order 2024-25).pdf"
            }
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              Constitutent-Affiliated College (BPUT Affiliation Order 2024-25)
            </span>
          </a>
        </Col>
        <Col md="3">
          <a
            href={
              process.env.PUBLIC_URL +
              "/Accreditation Ranking (Institutional Grade Sheet).pdf"
            }
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              Accreditation Ranking (Institutional Grade Sheet)
            </span>
          </a>
        </Col>
      </Row>

      <br />
      <br />

      <Row className="justify-content-center">
        <Col md="3">
          <a
            href={process.env.PUBLIC_URL + "/Approval - I UGC Letter.pdf"}
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">Approval - I UGC Letter</span>
          </a>
        </Col>
        <Col md="3">
          <a
            href={
              process.env.PUBLIC_URL +
              "/Approval-II AICTE EOA Report 2024-25.pdf"
            }
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">Approval-II AICTE EOA Report 2024-25</span>
          </a>
        </Col>
        <Col md="3">
          <a
            href={
              process.env.PUBLIC_URL +
              "/Sponsoring Agency - Trust Deed - NEMT.pdf"
            }
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">Sponsoring Agency - Trust Deed - NEMT</span>
          </a>
        </Col>

        <Col md="3">
          <a
            href={
              process.env.PUBLIC_URL + "/MoU with Different Organisations.pdf"
            }
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">MoU with Different Organisations</span>
          </a>
        </Col>
      </Row>

      <br />
      <br />

      <Row className="justify-content-center">
        <Col md="3">
          <a
            href={process.env.PUBLIC_URL + "/Annual Report.pdf"}
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">Annual Report</span>
          </a>
        </Col>
        <Col md="3">
          <a
            href={process.env.PUBLIC_URL + "/Annual Accounts.pdf"}
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">Annual Accounts</span>
          </a>
        </Col>
      </Row>
    </PageSection>
  );
};

export default LeadershipArchiveResponsive;

import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";

import "./trusteeMessageCard.style.scss";

const TrusteeMessageCard = ({
	titlePrefix,
	imageUrl,
	name,
	designation,
	feedback,
	type,
}) => {
	return type === "chairman" ? (
    <Row className="trustee-message-card">
      <Col md="4" className="mb-2 mb-md-0">
        <Image
          src={imageUrl.default ? imageUrl.default : imageUrl}
          alt={designation}
          className="img-fluid trustee-message-card--image"
        />
      </Col>
      <Col md="8">
        <div className="trustee-message-card--content">
          <ResponsivePageHeader
            marginClass="mb-3"
            title={titlePrefix ? `${titlePrefix} ${designation}` : designation}
          />
          {/* <p className="quote">{feedback}</p> */}

          <p
            className="quote"
            dangerouslySetInnerHTML={{
              __html: feedback,
            }}
          />
          <p className="font-weight-medium text-highlight-color">{`- ${name}`}</p>
        </div>
      </Col>
    </Row>
  ) : (
    <Row className="trustee-message-card  mt-5">
      <Col md="8">
        <div className="trustee-message-card--content">
          <ResponsivePageHeader
            marginClass="mb-3"
            title={titlePrefix ? `${titlePrefix} ${designation}` : designation}
          />
          <p className="quote">{feedback}</p>
          <p className="font-weight-medium text-highlight-color">{`- ${name}`}</p>
        </div>
      </Col>
      <Col md="4" className="mb-2 mb-md-0">
        <Image
          src={imageUrl.default ? imageUrl.default : imageUrl}
          alt={designation}
          className="img-fluid trustee-message-card--image"
        />
      </Col>
    </Row>
  );
};

export default TrusteeMessageCard;

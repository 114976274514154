export const mcaElectivesData = [
	{
		iconUrl: require("../assets/electives/MCA/2.svg"),
		imageUrl: require("../assets/electives/MCA/bg1.png"),
		title: "Data Sciences",
		overlayType: "black",
		electivesList: [
			"Introduction to Data Science",
			"Artificial Intelligence",
			"Machine learning",
			"Big Data Analytics",
			"Data Mining",
			"Deep learning",
			"Data analytics",
			"Intelligence Data Analytics",
		],
	},
	{
		iconUrl: require("../assets/electives/MCA/2.svg"),
		imageUrl: require("../assets/electives/MCA/bg2.jpg"),
		title: "Cyber Security",
		overlayType: "blue",
		electivesList: [
			"Computer Network Security",
			"Cybersecurity & Law",
			"Advanced Computer Networks",
		],
	},
	{
		iconUrl: require("../assets/electives/MCA/2.svg"),
		imageUrl: require("../assets/electives/MCA/bg3.jpg"),
		title: "Mobile & Cloud technologies",
		overlayType: "blue",
		electivesList: ["Cloud Computing ", "Mobile Computing"],
	},
	{
		iconUrl: require("../assets/electives/MCA/2.svg"),
		imageUrl: require("../assets/electives/MCA/bg4.jpg"),
		title: "Wireless Communication",
		overlayType: "black",
		electivesList: ["Embedded system", "Wireless Sensor Networks"],
	},
	{
		iconUrl: require("../assets/electives/MCA/2.svg"),
		imageUrl: require("../assets/electives/MCA/bg5.jpg"),
		title: "Computer Algorithms",
		overlayType: "black",
		electivesList: [
			"Soft Computing",
			"Computer based Optimization Techniques",
			"Digital Image Processing ",
			"Simulation & Modeling",
		],
	},
	{
		iconUrl: require("../assets/electives/MCA/2.svg"),
		imageUrl: require("../assets/electives/MCA/bg6.jpg"),
		title: "System Design & Fundamentals",
		overlayType: "blue",
		electivesList: [
			"Information System Design",
			"Real-time system",
			"Distributed Operating System",
			"Advanced Computer Architecture",
			"Computer Graphics & Multimedia",
		],
	},

	{
		iconUrl: require("../assets/electives/MCA/2.svg"),
		imageUrl: require("../assets/electives/MCA/bg7.jpg"),
		title: "Technology Management",
		overlayType: "blue",
		electivesList: [
			"Intellectual Property Rights",
			"Management INformation System ",
			"Software Project Management ",
			"E-Commerce & ERP ",
		],
	},
	{
		iconUrl: require("../assets/electives/MCA/2.svg"),
		imageUrl: require("../assets/electives/MCA/bg8.jpg"),
		title: "DBMS ",
		overlayType: "black",
		electivesList: ["Advanced Database Management System"],
	},
];

export const mbaElectivesData = [
  {
    iconUrl: require("../assets/electives/MBA/1.svg"),
    imageUrl: require("../assets/electives/MBA/bg1.png"),
    title: "Marketing area",
    overlayType: "black",
    electivesList: [
      "Consumer Behavior",
      "Sales and Distribution Management",
      "Services Marketing",
      "Retail Management",
      "Product & Branding Marketing",
      "B2B Marketing",
      "Digital marketing",
    ],
  },
  {
    iconUrl: require("../assets/electives/MBA/3.svg"),
    imageUrl: require("../assets/electives/MBA/bg3.png"),
    title: "Finance Area",
    overlayType: "blue",
    electivesList: [
      "Mergers & Corporate Restructuring",
      "Security Analysis & Portfolio Management ",
      "Project Appraisal & Financing",
      "Financial Derivative",
      "Advanced Management Accounting",
      "Business Taxation",
      "Behavioral Finance",
    ],
  },
  {
    iconUrl: require("../assets/electives/MBA/3.svg"),
    imageUrl: require("../assets/electives/MBA/bg3.png"),
    title: "HR Area",
    overlayType: "blue",
    electivesList: [
      "Compensation and Benefit Management",
      "Manpower Planning",
      "Performance Management System",
      "Industrial Legislations ",
      "Employee Relations",
      "Team Dynamics at work ",
      "Strategic HRM ",
    ],
  },
  {
    iconUrl: require("../assets/electives/MBA/5.svg"),
    imageUrl: require("../assets/electives/MBA/bg5.png"),
    title: "Operations Management",
    overlayType: "black",
    electivesList: [
      "Supply Chain Management & logistics ",
      "Pricing & revenue Management ",
      "Operations Strategy ",
      "Sales & Operation Planning ",
      "Management of Manufacturing System ",
      "Sourcing Management ",
      "Operations Research Applications ",
    ],
  },
  {
    iconUrl: require("../assets/electives/MBA/2.svg"),
    imageUrl: require("../assets/electives/MBA/bg2.png"),
    title: "IT area",
    overlayType: "black",
    electivesList: [
      "Software Engineering (SE)",
      "Networking Management(NM)",
      "Database Management(DBM)",
      "Information Security & Cyber Law (ISCL)",
    ],
  },
];

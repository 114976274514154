import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";

import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";

const ECellContentResponsive = () => {
  const [currentBreakPoint, setCurrentBreakPoint] = useState("");

  const descriptionClasses = classNames(
    "text-white mb-4 text-center text-text-color",
    {
      "font-sm":
        currentBreakPoint === "xSmall" || currentBreakPoint === "small",
    }
  );

  useEffect(() => {
    bsBreakpoints.init();
    setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

    const handleBreakPoint = (event) => {
      setCurrentBreakPoint(event.detail);
    };

    window.addEventListener("new.bs.breakpoint", handleBreakPoint);

    return () => {
      window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
    };
  }, []);

  return (
    <PageSection>
      <ResponsivePageHeader centered={true} title="Duties" />
      <div className="ml-2">
        <ul>
          <li>
            <p>
              To conduct the Internal/ External/ Practical Examinations for all
              the programmes offered in the College.
            </p>
          </li>
          <li>
            <p>
              To prepare Time Table for the above-mentioned examinations in
              advance and inform the students about the same.
            </p>
          </li>
          <li>
            <p>
              To allot the exam halls for all the examinations conducted in the
              College.
            </p>
          </li>
          <li>
            <p>
              To ensure that all the question papers are prepared well in
              advance.
            </p>
          </li>
          <li>
            <p>
              To collect all the answer scripts and the supporting documents
              required.
            </p>
          </li>
          <li>
            <p>To arrange for External/Internal Valuation of Exams.</p>
          </li>
          <li>
            <p>
              To publish the results and take necessary steps for the conduct of
              supplementary exams in time.
            </p>
          </li>
          <li>
            <p>
              To address the grievances/ complaints of the students and staff
              concerning exam- related issues.
            </p>
          </li>
        </ul>
      </div>
    </PageSection>
  );
};

export default ECellContentResponsive;

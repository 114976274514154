// import React from "react";
import { Row, Col } from "react-bootstrap";
// import PageWrapper from "../../components/PageWrapper/PageWrapper";
import PageHeader from "../../components/PageHeader/PageHeader";
import PageSection from "../../components/PageSection/PageSection";
import FacultyCard from "../../components/FacultyCard/FacultyCard";
import { mbaFacultyData, mcaFacultyData } from "../../static-data/faculty.data";
import PageSectionHeader from "../../components/PageSectionHeader/PageSectionHeader";
import React, { useState } from "react";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import HeroSection from "../../components/HeroSection/HeroSection";
import HeroCaption from "../../components/HeroCaption/HeroCaption";
import CourseInfoResponsive from "../../components/CourseInfoResponsive/CourseInfoResponsive";
import FacultyResponsive from "../../components/FacultyResponsive/FacultyResponsive";
import CoursesResponsive from "../../components/CoursesResponsive/CoursesResponsive";
import ReactModal from "react-modal";
import TrusteeMessageCard from "../../components/TrusteeMessageCard/TrusteeMessageCard";
import ResponsivePageHeader from "../../components/ResponsivePageHeader/ResponsivePageHeader";
import { principalData } from "../../static-data/chairman.data";
import "./FacultyPage.scss";

// const FacultyPage = () => {
// 	return (
// 		<PageWrapper>
// 			<PageHeader title="Faculty" />
// <PageSection>
// 	<p>
// 		NIBA educators carry master knowledge and guidance to the study hall.
// 		They draw upon broad experience just as bits of knowledge from their
// 		forefront research. Known for simple availability and their
// 		cooperative way to deal with educating, you'll gain from the best
// 		business hypothesis and practice meet up.{" "}
// 	</p>

// 	<p>
// 		These educators by and large encourage the normal center courses in
// 		the NIBA MBA program. For more insights on our faculty, check out our
// 		catalogues and teacher directories.
// 	</p>
// </PageSection>
// <PageSection bgClass="light">
// 	<>
// 		<PageSectionHeader title="MBA Faculty" leftStyle="bottom" center />
// 		<Row>
// 			{mbaFacultyData &&
// 				mbaFacultyData.map((data) => (
// 					<Col
// 						md="6"
// 						key={data.email}
// 						className="d-flex align-itmes-stretch mb-4"
// 					>
// 						<FacultyCard {...data} />
// 					</Col>
// 				))}
// 		</Row>
// 	</>
// </PageSection>
// <PageSection>
// 	<>
// 		<PageSectionHeader title="MCA Faculty" rightStyle="bottom" center />
// 		<Row>
// 			{mcaFacultyData &&
// 				mcaFacultyData.map((data) => (
// 					<Col
// 						md="6"
// 						key={data.email}
// 						className="d-flex align-itmes-stretch mb-4"
// 					>
// 						<FacultyCard {...data} />
// 					</Col>
// 				))}
// 		</Row>
// 	</>
// </PageSection>
// 		</PageWrapper>
// 	);
// };

// export default FacultyPage;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    backgroundColor: "#00000073",
  },
};

const FacultyPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <PageWrapper className="mt-n6">
      <HeroSection className="bg-page-background-7">
        <HeroCaption
          title="Faculty"
          position="bottom-left"
          breadcrumb={true}
          breadcrumbPrefix="Home"
        />
      </HeroSection>

      <FacultyResponsive />

      <PageSection bgClass="white">
        <TrusteeMessageCard
          type="chairman"
          titlePrefix="From Our"
          {...principalData}
        />

        <ResponsivePageHeader centered={true} title="PROFILE OF PRINCIPAL" />
        <div className="ml-2">
          <ul>
            <li>
              <p>
                He has Academic & Industry experience for more than 27 years,
                which includes 26 years of teaching in different reputed
                professional colleges in Management and one year in Industry.
              </p>
            </li>
            <li>
              <p>
                Presented more than 15 research papers in international and
                national conferences and published two articles.
              </p>
            </li>
            <li>
              <p>
                He has published more than 10 Books for Intermediate, UG and PG
                education throughout India.
              </p>
            </li>
            <li>
              <p>
                Possessing rich knowledge in Finance and allied Management
                Subjects.
              </p>
            </li>
            <li>
              <p>
                Worked as the Associated Professor under Heritage Vision
                Educational Trust in BJB College, Ravenshaw University and many
                other institutions of repute.
              </p>
            </li>
            <li>
              <p>
                Six study materials on Accounting for Odisha State Open
                University, Odisha.
              </p>
            </li>
            <li>
              <p>
                Edited the study material on “Analysis of Financial Statements”
                for Odisha State Open University, Odisha.
              </p>
            </li>
            <li>
              <p>
                Prepared Four video lecturers for Odisha State Open University,
                Odisha.
              </p>
            </li>
            <li>
              <p>
                Editorial Board member for Cognitive Discourses: An
                International Journal.
              </p>
            </li>
            <li>
              <p>Life member of OCA, OEA, AIMS, III, AIMA</p>
            </li>
          </ul>
        </div>
      </PageSection>

      {/* <CoursesResponsive /> */}

      {/* <PageSection>
				<>
					<Row>
						{mbaFacultyData &&
							mbaFacultyData.map((data) => (
								<Col
									md="6"
									key={data.email}
									className="d-flex align-itmes-stretch mb-4"
								>
									<FacultyCard {...data} />
								</Col>
							))}
					</Row>
				</>
			</PageSection> */}

      <PageSection bgClass="light">
        <>
          <Row>
            {mbaFacultyData &&
              mbaFacultyData.map((data) => (
                <Col
                  md="6"
                  key={data.email}
                  className=" align-itmes-stretch mb-4"
                >
                  <FacultyCard {...data} />
                </Col>
              ))}
          </Row>
        </>
            
      </PageSection>
      {/*<PageSection>
				<>
					<PageSectionHeader title="MCA Faculty" rightStyle="bottom" center />
					<Row>
						{mcaFacultyData &&
							mcaFacultyData.map((data) => (
								<Col
									md="6"
									key={data.email}
									className="d-flex align-itmes-stretch mb-4"
								>
									<FacultyCard {...data} />
								</Col>
							))}
					</Row>
				</>
			</PageSection> */}
    </PageWrapper>
  );
};

export default FacultyPage;

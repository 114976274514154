import React from "react";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import HeroSection from "../../components/HeroSection/HeroSection";
import HeroCaption from "../../components/HeroCaption/HeroCaption";
import AcademicAdvisorsResponsive from "../../components/AcademicAdvisorsResponsive/AcademicAdvisorsResponsive";
import ChairmanResponsive from "../../components/ChairmanResponsive/ChairmanResponsive";
import AdvisorResponsive from "../../components/AdvisorResponsive/AdvisorResponsive";
import MemberResponsive from "../../components/MemberResponsive/MemberResponsive";
import LeadershipArchiveResponsive from "../../components/LeadershipArchiveResponsive/LeadershipArchiveResponsive";


const AcademicAdvisorPage = () => {
	return (
		<PageWrapper className="mt-n6">
			<HeroSection className="bg-page-background-11">
				<HeroCaption
					title="Leadership & Management"
					position="bottom-left"
					breadcrumb={true}
					breadcrumbPrefix="Home"
				/>
			</HeroSection>
			<AcademicAdvisorsResponsive />
			<ChairmanResponsive />
			<MemberResponsive />
			<AdvisorResponsive />
			<LeadershipArchiveResponsive/>
			
		</PageWrapper>
	);
};

export default AcademicAdvisorPage;

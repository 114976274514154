import React from "react";
import PageHeader from "../../components/PageHeader/PageHeader";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import PageSection from "../../components/PageSection/PageSection";
import HeroSection from "../../components/HeroSection/HeroSection";
import HeroCaption from "../../components/HeroCaption/HeroCaption";
import ECellContentResponsive from "../../components/ECellContentResponsive/ECellContentResponsive";
import ECellResponsive from "../../components/ECellResponsive/ECellResponsive";

const ECellPage = () => {
  return (
    <PageWrapper className="mt-n6">
      <HeroSection className="bg-page-background-4">
        <HeroCaption
          title="E-Cell"
          position="bottom-left"
          breadcrumb={true}
          breadcrumbPrefix="Home"
        />
      </HeroSection>
      <ECellResponsive />
      <ECellContentResponsive />
    </PageWrapper>
  );
};

export default ECellPage;


import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import { AiFillFilePdf } from "react-icons/ai";
import CustomIcon from "../CustomIcon/CustomIcon";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";

const InternalComplaintResponsive = () => {
  const [currentBreakPoint, setCurrentBreakPoint] = useState("");

  useEffect(() => {
    bsBreakpoints.init();
    setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

    const handleBreakPoint = (event) => {
      setCurrentBreakPoint(event.detail);
    };

    window.addEventListener("new.bs.breakpoint", handleBreakPoint);

    return () => {
      window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
    };
  }, []);

  const descriptionClasses = classNames("mb-4 text-center text-text-color", {
    "font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
  });

  return (
    <PageSection bgClass="background-color">
      <ResponsivePageHeader
        centered={true}
        title="Internal Complaint Committee"
      />
      <p className={descriptionClasses}>
        The existing committee for the year 2024-25 is reconstituted as under
        taking consent of each selected member and committed to provide all
        women who fall within its jurisdiction including its academic,
        non-academic staff and students, a place of work and study, free from
        sexual harassment, intimidation and exploitation. Every woman shall have
        a right to work in an environment free from any form of Sexual
        Harassment. The Committee consists of members of the faculty,
        administration staff, service staff and student representatives.
      </p>

      <br />

      <PageSection bgClass="white">
        <ol>
          <li> Dr. Sradhanjali Nayak (Chairperson)</li>
          <li> Prof. Vineta Debyani Mishra (Member)</li>
          <li> Prof. Sumita Dhar (Member)</li>
          <li> Prof. Rasmita Panda (Member)</li>
          <li> Prof. Ankita Jena (Member)</li>
          <li> Ms. Jogamaya Pattanaik</li>
          <li> Prof. S. D. Samal (Member)</li>
          <li> Prof. Pravakar Mishra (Member)</li>
          <li> Students Representatives (4 Nos.) (Member)</li>
        </ol>
      </PageSection>

      <PageSection>
        <ResponsivePageHeader centered={true} title="Primary Objective" />
        <div className="ml-2">
          <ul>
            <li>
              <p>
                To comply with the provisions of the Sexual Harassment at
                Workplace (Prevention, Prohibition and Redressal) Act, 2013
                (Act) and to develop and implement a policy against sexual
                harassment of women.
              </p>
            </li>
            <li>
              <p>
                To uphold Women's Right to Protection against Sexual Harassment
                and for the prevention and redressal of sexual harassment of
                women.
              </p>
            </li>
            <li>
              <p>
                To evolve a permanent mechanism for the prevention, prohibition
                and redressal of sexual harassment of women.
              </p>
            </li>
            <li>
              <p>
                To actively promote a social, physical and psychological
                environment that will raise awareness about and deter acts of
                sexual harassment of women.
              </p>
            </li>
            <li>
              <p>
                To undertake all necessary and reasonable steps including the
                constitution of appropriate committees for purposes of gender
                sensitization and to conduct enquiries into complaints of sexual
                harassment.
              </p>
            </li>
          </ul>
        </div>
      </PageSection>
    </PageSection>
  );
};

export default InternalComplaintResponsive;

import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import { AiFillFilePdf } from "react-icons/ai";
import CustomIcon from "../CustomIcon/CustomIcon";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";

const RTIResponsive = () => {
  const [currentBreakPoint, setCurrentBreakPoint] = useState("");

  useEffect(() => {
    bsBreakpoints.init();
    setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

    const handleBreakPoint = (event) => {
      setCurrentBreakPoint(event.detail);
    };

    window.addEventListener("new.bs.breakpoint", handleBreakPoint);

    return () => {
      window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
    };
  }, []);

  const descriptionClasses = classNames("mb-4 text-center text-text-color", {
    "font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
  });

  return (
    <PageSection bgClass="background-color">
      <ResponsivePageHeader centered={true} title="RTI" />
      <p className={descriptionClasses}>
        Right to Information Act, 2005 mandates timely response to citizens’
        requests for government information. The basic object of the Right to
        Information Act is to empower the citizens, promote transparency and
        accountability in the working of government. Keeping in line with the
        RTI objectives, NIIS Institute of Business Administration has also
        constituted the RTI Cell to enable the students, citizens and the
        stakeholders to have necessary access the RTI related
        information/disclosures. The Cell makes speedy and timely disposal of
        the queries and grievances lodged by individual or stakeholders time to
        time. We assert in enabling the general public to have greater access to
        domain of information systematically preserved by our office.
      </p>

      <PageSection bgClass="white">
        <ol>
          <li> Dr. Dushmant Kumar Pattnaik (President)</li>
          <li>Dr. Rajesh Kumar Sain (Convener)</li>
          <li> Mr. Pitamber Chayani (Member)</li>
          <li> Prof. Gyana Ranjan Biswal (Member)</li>
        </ol>
      </PageSection>
    </PageSection>
  );
};

export default RTIResponsive;


import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import { AiFillFilePdf } from "react-icons/ai";
import CustomIcon from "../CustomIcon/CustomIcon";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";

const HostelResponsive = () => {
  const [currentBreakPoint, setCurrentBreakPoint] = useState("");

  useEffect(() => {
    bsBreakpoints.init();
    setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

    const handleBreakPoint = (event) => {
      setCurrentBreakPoint(event.detail);
    };

    window.addEventListener("new.bs.breakpoint", handleBreakPoint);

    return () => {
      window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
    };
  }, []);

  const descriptionClasses = classNames("mb-4 text-center text-text-color", {
    "font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
  });

  return (
    <PageSection bgClass="background-color">
      <ResponsivePageHeader centered={true} title="Hostel Details" />
      <p className={descriptionClasses}>
        The institute has separate hostel facilities for both Boys and Girls
        with all modern provision of living. Recreational facilities are also
        provided to the boarders of the Hostel. The hostel committe is
        monitoring the administration of Hostels. <br /> The Hostel committee
        constitute the following members for smooth functioning of the Hostel
        Activities.
      </p>

      <br />

      <PageSection bgClass="white">
        <ol>
          <li>
            Prof. Satyajit Pattanaik, Assistant Professor - (Hostel
            Superintendent - Boys)
          </li>
          <li>
            Ms. Jogamaya Pattnaik, Office Executive - (Hostel Superintendent -
            Girls)
          </li>
          <li> Prof. Pravakar Mishra, HOD, MCA (Member)</li>
          <li> Prof. S. D. Samal, HOD, MBA (Member)</li>
          <li> Ms. Mousumi Nayak, DEO (Member)</li>
          <li> Mr. Pitamber Chayani (Member)</li>
          <li> Prof. Chinmay Kumar Rout (Member)</li>
          <li> Prof. Uttam Giri (Member)</li>
        </ol>
      </PageSection>
    </PageSection>
  );
};

export default HostelResponsive;


import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";

import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";

const HostelContentResponsive = () => {
  const [currentBreakPoint, setCurrentBreakPoint] = useState("");

  const descriptionClasses = classNames(
    "text-white mb-4 text-center text-text-color",
    {
      "font-sm":
        currentBreakPoint === "xSmall" || currentBreakPoint === "small",
    }
  );

  useEffect(() => {
    bsBreakpoints.init();
    setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

    const handleBreakPoint = (event) => {
      setCurrentBreakPoint(event.detail);
    };

    window.addEventListener("new.bs.breakpoint", handleBreakPoint);

    return () => {
      window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
    };
  }, []);

  return (
    <PageSection>
      <ResponsivePageHeader centered={true} title="Role of Hostel Committee" />
      <div className="ml-2">
        <ul>
          <li>
            <p>
              The Superintendents of respective Hostels, shall inform to the
              Council of Wardens regarding the effective discharge of duties by
              the Hostel Committee.
            </p>
          </li>
          <li>
            <p>
              The hostel committee shall take active interest in general welfare
              of the students residing in the hostel and assist the Council of
              Wardens in maintaining the living standards.
            </p>
          </li>
          <li>
            <p>
              The hostel committee shall report to the Chairman, Council of
              Wardens on the services given by the hostel staff and contractors.
            </p>
          </li>
          <li>
            <p>
              The hostel committee shall assist the Council of Wardens in the
              timely allotment of the rooms, report any unauthorised use or
              misuse of hostel or guest rooms and bring to notice any untoward
              incidence occurring in the Hostel premises.
            </p>
          </li>
          <li>
            <p>
              The hostel committee shall supervise and ensure proper and limited
              use of electricity and water in the hostel premises.
            </p>
          </li>
        </ul>
      </div>
    </PageSection>
  );
};

export default HostelContentResponsive;


import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import { AiFillFilePdf } from "react-icons/ai";
import CustomIcon from "../CustomIcon/CustomIcon";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";

const ECellResponsive = () => {
  const [currentBreakPoint, setCurrentBreakPoint] = useState("");

  useEffect(() => {
    bsBreakpoints.init();
    setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

    const handleBreakPoint = (event) => {
      setCurrentBreakPoint(event.detail);
    };

    window.addEventListener("new.bs.breakpoint", handleBreakPoint);

    return () => {
      window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
    };
  }, []);

  const descriptionClasses = classNames("mb-4 text-center text-text-color", {
    "font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
  });

  return (
    <PageSection bgClass="background-color">
      <ResponsivePageHeader centered={true} title="Examination Cell" />
      <p className={descriptionClasses}>
        The Examination committee is a statutory body of the college. Hence all
        procedures, rules and regulations are strictly adhered to as per the
        ordinances of the university. Along with above the internal squad formed
        by the Principal of the Institute to check unfair means during the
        examination. <br /> The Examination committee constitute the following
        members for smooth functioning of the Examination.
      </p>

      <br />

      <PageSection bgClass="white">
        <ol>
          <li> Dr. Rajesh Kumar Sain, Principal (Centre Superintendent)</li>
          <li>
            Prof. Vineta Debayani Mishra, Assistant Professor (Examination
            Coordinator)
          </li>
          <li> Prof. Pravakar Mishra, HOD, MCA (Member)</li>
          <li> Prof. S. D. Samal, HOD, MBA (Member)</li>
          <li> Ms. Mousumi Nayak, DEO (Examination Assistant)</li>
        </ol>
      </PageSection>
    </PageSection>
  );
};

export default ECellResponsive;

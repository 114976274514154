import React from "react";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import HeroSection from "../../components/HeroSection/HeroSection";
import HeroCaption from "../../components/HeroCaption/HeroCaption";
import SexualHarassmentCellResponsive from "../../components/SexualHarassmentCellResponsive/SexualHarassmentCellResponsive";
import InternalComplaintResponsive from "../../components/InternalComplaintResponsive/InternalComplaintResponsive";

const SexualHarassmentCellPage = () => {
	return (
		<PageWrapper className="mt-n6">
			<HeroSection className="bg-page-background-11">
				<HeroCaption
					title="Sexual Harassment Cell"
					position="bottom-left"
					breadcrumb={true}
					breadcrumbPrefix="Home"
				/>
			</HeroSection>
			<SexualHarassmentCellResponsive />
			<InternalComplaintResponsive/>
		</PageWrapper>
	);
};

export default SexualHarassmentCellPage;

import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import { AiFillFilePdf } from "react-icons/ai";
import CustomIcon from "../CustomIcon/CustomIcon";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import { Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
const NAACSSRResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");
	// const [currentRoute, setCurrentRoute] = useState("");
	const location = useLocation();
	// console.log(location.pathname);

	useEffect(() => {
		// setCurrentRoute(location.pathname);
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	const descriptionClasses = classNames("mb-4 text-center text-text-color", {
		"font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
	});

	return (
		<PageSection bgClass="background-color">
			<ResponsivePageHeader centered={true} title="SSR" />

			<a
				href={process.env.PUBLIC_URL + "/SSR Report.pdf"}
				className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
				target="_blank"
				rel="noopener noreferrer"
			>
				<CustomIcon currentIcon={AiFillFilePdf} size="md" />
				<span className="ml-2">View SSR Report</span>
			</a>

			<br/>

			<Row className="justify-content-between">
				<Col md="6" className="mb-2 mb-md-0 ">
					<div
						className="border mb-4 rounded px-5 pt-1 pb-4 bg-light"
						style={{ height: "90%" }}
					>
						<p className="display-10 mb-0 mt-3 font-weight-normal font-heading-font">
							Criteria 1
						</p>
						<Row>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/1.1.1  Effective Curriculum Planning.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">
										1.1.1 Effective Curriculum Planning
									</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr/1.2.1 AND 1.2.2 Add-on Programmes.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">1.2.1 AND 1.2.2 Add-on Programme</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/1.3.1 Institution Integrates Crosscutting Issues.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">
										1.3.1 Institution Integrates Crosscutting Issues
									</span>
								</a>
							</Col>

							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/1.3.2 MBA _ MCA Projects.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">1.3.2 MBA _ MCA Projects</span>
								</a>
							</Col>

							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL + "/ssr/1.4.1 Student Feedback.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">1.4.1 Student Feedback</span>
								</a>
							</Col>
						</Row>
					</div>
				</Col>
				<Col md="6" className="mb-2 mb-md-0 ">
					<div
						className="border mb-4 rounded px-5 pt-1 pb-4 bg-light"
						style={{ height: "90%" }}
					>
						<p className="display-10 mb-0 mt-3 font-weight-normal font-heading-font">
							Criteria 2
						</p>
						<Row>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr/2.1.1 Student Details 2017-18 to 2021-22.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">
										2.1.1 Student Details 2017-18 to 2021-22
									</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr/2.1.2 Seats Filled-up by Reservation Category.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">
										2.1.2 Seats Filled-up by Reservation Category
									</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr/2.2.1 Student -Teacher Ratio.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">2.2.1 Student -Teacher Ratio</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/2.3.1 Student Centric Methods.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">2.3.1 Student Centric Methods</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr/2.4.1 Percentage of Full time Teachers.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">
										2.4.1 Percentage of Full time Teachers
									</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr/2.4.2 Full Time Teachers with NET_PHD.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">
										2.4.2 Full Time Teachers with NET_PHD
									</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/2.5.1 Mechanism of Internal -External Assessment.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">
										2.5.1 Mechanism of Internal -External Assessment
									</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/2.6.1 Programme Outcomes (POs) and Course Outcomes (COs).pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">
										2.6.1 Programme Outcomes (POs) and Course Outcomes (COs)
									</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/2.6.2 Attainment of POs and COs.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">2.6.2 Attainment of POs and COs</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/2.6.3 Pass _ of Students.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">2.6.3 Pass _ of Students</span>
								</a>
							</Col>
							{/* <Col md="6">
								<a
									href={
										process.env.PUBLIC_URL + "/ssr/2.6.2 Pass _ of Students.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">2.6.2 Pass _ of Students</span>
								</a>
							</Col> */}
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr/2.7.1 Online Student Satisfaction Survey.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">
										2.7.1 Online Student Satisfaction Survey
									</span>
								</a>
							</Col>
						</Row>
					</div>
				</Col>
				<Col md="6" className="mb-2 mb-md-0 ">
					<div
						className="border mb-4 rounded px-5 pt-1 pb-4 bg-light"
						style={{ height: "90%" }}
					>
						<p className="display-10 mb-0 mt-3 font-weight-normal font-heading-font">
							Criteria 3
						</p>
						<Row>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr/3.1 Expenditure Excluding Salary 2017-18 to 2021-22.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">
										3.1 Expenditure Excluding Salary 2017-18 to 2021-22
									</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr/3.1.1 Grant Received from Govt and Non-Govt Resources.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">
										3.1.1 Grant Received from Govt and Non-Govt Resources
									</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/3.2.1 Institution has Created an Ecosystem for Innovations.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">
										3.2.1 Institution has Created an Ecosystem for Innovations
									</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr/3.2.2 Workshop-Seminars-IPR.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">3.2.2 Workshop-Seminars-IPR</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr/3.3.1 Journal Publications.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">3.3.1 Journal Publications</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/3.3.2 Publication of Books.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">3.3.2 Publication of Books</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/3.4.1 Outcomes of Extension Activites.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">
										3.4.1 Outcomes of Extension Activites
									</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/3.4.2 Awards and Recognitions.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">3.4.2 Awards and Recognitions</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL + "/ssr/3.4.3 Outreach Programs.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">3.4.3 Outreach Programs</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL + "/ssr/3.5.1 MOUs Collaboration.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">3.5.1 MOUs Collaboration</span>
								</a>
							</Col>
						</Row>
					</div>
				</Col>
				<Col md="6" className="mb-2 mb-md-0 ">
					<div
						className="border mb-4 rounded px-5 pt-1 pb-4 bg-light"
						style={{ height: "90%" }}
					>
						<p className="display-10 mb-0 mt-3 font-weight-normal font-heading-font">
							Criteria 4
						</p>
						<Row>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/4.1.1 Infrastructure and Other Facilities.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">
										4.1.1 Infrastructure and Other Facilities
									</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr/4.1.2 _ 4.4.1 Expenditure.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">4.1.2 _ 4.4.1 Expenditure</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/4.2.1 Library is Automated with Digital Facilities.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">
										4.2.1 Library is Automated with Digital Facilities
									</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/4.3.1 Frequently Updates IT Facilities.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">
										4.3.1 Frequently Updates IT Facilities
									</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/4.3.2 Extract of Stock Register.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">4.3.2 Extract of Stock Register</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/4.3.2 Student Computer Ratio.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">4.3.2 Student Computer Ratio</span>
								</a>
							</Col>
						</Row>
					</div>
				</Col>
				<Col md="6" className="mb-2 mb-md-0 ">
					<div
						className="border mb-4 rounded px-5 pt-1 pb-4 bg-light"
						style={{ height: "90%" }}
					>
						<p className="display-10 mb-0 mt-3 font-weight-normal font-heading-font">
							Criteria 5
						</p>
						<Row>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr/5.1.1 Student Benefits from Scholarship.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">
										5.1.1 Student Benefits from Scholarship
									</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL + "/ssr/5.1.2 Skill Enhancement.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">5.1.2 Skill Enhancement</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr/5.1.4 Statutory and Regulatory Bodies.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">
										5.1.4 Statutory and Regulatory Bodies
									</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/5.2.1 List of Students along with Placement Details.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">
										5.2.1 List of Students along with Placement Details
									</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/5.2.2 Qualifying in Higher Exam.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">5.2.2 Qualifying in Higher Exam</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/5.3.1 Awards-Medals for Outstanding Performance.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">
										5.3.1 Awards-Medals for Outstanding Performance
									</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/5.3.1 Number of Awards -Medals for Outstanding Performance.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">
										5.3.1 Number of Awards -Medals for Outstanding Performance
									</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/5.3.2 Average Number of Sports _ Cultural Program.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">
										5.3.2 Average Number of Sports _ Cultural Program
									</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/5.4.1 Alumni Engagement.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">5.4.1 Alumni Engagement</span>
								</a>
							</Col>
						</Row>
					</div>
				</Col>
				<Col md="6" className="mb-2 mb-md-0 ">
					<div
						className="border mb-4 rounded px-5 pt-1 pb-4 bg-light"
						style={{ height: "90%" }}
					>
						<p className="display-10 mb-0 mt-3 font-weight-normal font-heading-font">
							Criteria 6
						</p>
						<Row>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/6.1.1 Institutional Governance and Leadership.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">
										6.1.1 Institutional Governance and Leadership
									</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/6.2.1 Institutional Perspective Plan.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">
										6.2.1 Institutional Perspective Plan
									</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/6.2.2 Annual E-Governance Report.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">6.2.2 Annual E-Governance Report</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/6.2.2 Implementation of e-Governance.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">
										6.2.2 Implementation of e-Governance
									</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/6.3.1 Faculty Empowerment Strategy.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">
										6.3.1 Faculty Empowerment Strategy
									</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr/6.3.2 Financial Support for Seminar-Workshop.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">
										6.3.2 Financial Support for Seminar-Workshop
									</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/6.3.1 Faculty Empowerment Strategy.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">
										6.3.1 Faculty Empowerment Strategy
									</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/6.3.3 Refreshers Corse FDP-MDP.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">6.3.3 Refreshers Corse FDP-MDP</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/6.4.1 Financial Management and Resource Mobilization.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">
										6.4.1 Financial Management and Resource Mobilization
									</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/6.5.1 IQAC Contributions fr Institutionalizing  the Quality Assurance Strategies.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">
										6.5.1 IQAC Contributions fr Institutionalizing the Quality
										Assurance Strategies
									</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/6.5.2 List of Collaborative Quality Initiatives.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">
										6.5.2 List of Collaborative Quality Initiatives
									</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/6.5.2 Minutes of IQAC Meetings.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">6.5.2 Minutes of IQAC Meetings</span>
								</a>
							</Col>
						</Row>
					</div>
				</Col>
				<Col md="6" className="mb-2 mb-md-0 ">
					<div
						className="border mb-4 rounded px-5 pt-1 pb-4 bg-light"
						style={{ height: "90%" }}
					>
						<p className="display-10 mb-0 mt-3 font-weight-normal font-heading-font">
							Criteria 7
						</p>
						<Row>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/7.1.1 Gender Audit and Measures for Promotion of Gender Equity.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">
										7.1.1 Gender Audit and Measures for Promotion of Gender
										Equity
									</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr/7.1.2 Institute Facilities andInitiatives.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">
										7.1.2 Institute Facilities andInitiatives
									</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/7.1.3 Report on Environmental Promotional Activities.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">
										7.1.3 Report on Environmental Promotional Activities
									</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/7.1.4 Institutional efforts Initiatives.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">
										7.1.4 Institutional efforts Initiatives
									</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL + "/ssr_2/7.2 Best Practices.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">7.2 Best Practices</span>
								</a>
							</Col>
							<Col md="6">
								<a
									href={
										process.env.PUBLIC_URL +
										"/ssr_2/7.3 Institutional Distinctiveness.pdf"
									}
									className="d-flex  text-uppercase  text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
									rel="noopener noreferrer"
								>
									<CustomIcon currentIcon={AiFillFilePdf} size="md" />
									<span className="ml-2">
										7.3 Institutional Distinctiveness
									</span>
								</a>
							</Col>
						</Row>
					</div>
				</Col>
			</Row>
		</PageSection>
	);
};

export default NAACSSRResponsive;

import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import { AiFillFilePdf } from "react-icons/ai";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import CustomIcon from "../CustomIcon/CustomIcon";

const SexualHarassmentCellResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	const descriptionClasses = classNames("text-center mb-4 text-text-color", {
		"font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
	});

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	return (
		<div>
			<PageSection bgClass="background-color">
				<ResponsivePageHeader centered={true} title="Sexual Harassment Cell" />
				<p style={{ textAlign: "left" }}>
                At NIIS, we prioritize the well-being and safety of every member of our community. We are committed to maintaining a campus 
                environment that is free from all forms of sexual harassment. To uphold this commitment, we have established a dedicated 
                Sexual Harassment Cell to address any concerns or incidents related to sexual harassment. 

                <br />
                <br />

                The Sexual Harassment Cell at NIIS 
                is a confidential and impartial body comprising trained professionals who are well-versed in handling such sensitive issues. 
                We provide a safe space for individuals to report incidents, seek guidance, and receive the necessary support to address their concerns. 
                We firmly believe in fostering a culture of respect, dignity, and inclusivity for all. 

                <br />
                <br />
                
                The Sexual Harassment Cell ensures that complaints 
                are handled with utmost sensitivity, confidentiality, and promptness. We follow a fair and transparent process, conducting thorough 
                investigations to ensure that all parties involved are heard and respected. Our primary goal is to provide a safe and supportive 
                environment for everyone, irrespective of gender, ensuring that no one faces any form of sexual harassment or discrimination.

				</p>

                <a
				href={
					process.env.PUBLIC_URL + "/SEXUAL HARASSMENT.pdf"
				}
				className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
				target="_blank"
				rel="noopener noreferrer"
			>
				<CustomIcon currentIcon={AiFillFilePdf} size="md" />
				<span className="ml-2">
					DOWNLOAD the PDF about Sexual Harssment 
				</span>
			</a>
				
			</PageSection>
			
		</div>
	);
};

export default SexualHarassmentCellResponsive;

export const mbaFacultyData = [
	{
		name: "Dr. Rajesh Sain",
		designation: "Professor, MBA & Served as Principal, NIBA & All committee Head",
		qualification:
			"M. Com from Burdwan University in 1997                                                                    MBA from Sikkim Manipal University in 2009                                                         Ph. D. from Ravenshaw University in 2016 ",
		email: "rajesh@niisgroup.org",
		experience: "25 years",
		teachingInterest: "FM,SAPM,PA & CMA",
		researchInterest: "Stock Market & Financial Inclusion",
		publication:
			"Published 4 National Journal and 1 International Journal, Article 14,  Edited 1 Book, 9 Books, & 10 seminars including FDPs",
		imageUrl: require("../assets/faculty/STAFF PHOTO/Rajesh Sain.JPG"),
	},
	{
		name: "Dr. P.K. Tripathy",
		designation: "Academic Adivsor, Acreditation responsibility for NAAC and NIRF & IQAC Head",
		qualification:
			"B. Sc Engineering , PGDEM, PGDM, Ph.D in 2008",
		email: "pktripathy@niisgroup.org",
		experience: "44 years",
		teachingInterest: "FM,SAPM,PA & CMA",
		researchInterest: "Stock Market & Financial Inclusion",
		publication: "2 Journals , 6 seminars, 9 projects, 6 books & 1 patent",
		imageUrl: require("../assets/faculty/STAFF PHOTO/Dr. P K. Tripathy.jpeg"),
	},
	{
		name: "Dr. Sisira Kanti Mishra",
		designation: "Professor, MBA & Represents Research & Innovation Cell, IQAC Cell.",
		qualification:
			"M. Com (Finance Specialisation) from Berhampur University in 1990,  M. Phil (Finance Specialisation ) from Berhempur University in  1995, LLB from Berhempur University in 1996, M. Phil  (Industrial Finance Specialisation) from Berhampur University in 1997, Ph. D. from Berhempur University  in  2003",
		email: "skmishra@niisgroup.org",
		experience: "28 years",
		teachingInterest: "FM,SAPM,PA & CMA",
		researchInterest: "Stock Market & Financial Inclusion",
		publication: "54 Journals, 2 Books, 26 seminars & 100 projects",
		imageUrl: require("../assets/faculty/STAFF PHOTO/Sisira Kanti Mishra Photo.jpg"),
	},
	{
		name: "Dr. Rojalini Patro",
		designation: "Professor, MCA",
		qualification:
			"M. Sc. from Khallikote College, Berhampur in 2010, M. Phil  from Sambalpur University, Burla in 2012, Ph. D. from SOA University  in  2019",
		email: "rojalin@niisgroup.org",
		experience: "13 years",
		teachingInterest: "FM,SAPM,PA & CMA",
		researchInterest: "Stock Market & Financial Inclusion",
		publication: "12 Journals",
		imageUrl: require("../assets/faculty/STAFF PHOTO/Dr. Rosalin Patra.jpg"),
	},
	{
		name: "Dr. Dushmanta Kumar Pattanaik",
		designation: "Anti Ragging, RTI Cell & Placement Head ",
		qualification:
			"M. A. in English from Utkal University in 2004, Ph. D. from VSSUT   in  English in 2021",
		email: " dusmanta@niisgroup.org",
		experience: "17 years",
		teachingInterest: "FM,SAPM,PA & CMA",
		researchInterest: "Stock Market & Financial Inclusion",
		publication: "4 Journals & 10 seminars",
		imageUrl: require("../assets/faculty/STAFF PHOTO/D. K. Pattanaik.JPG"),
	},

	{
		name: "Prof. Bhabani Sankar Rath",
		designation: "Anti Ragging, Hostel, IQAC, Research Cell,                                                           ",
		qualification:
			'MBA from IGNOU in 2005  "B" Level from   DOEACE  in 2007                                                                                                                 M. Sc (IT) from KSOU, Mysore in 2010                                                                          M. Tech (IT) from KSOU, Mysore in 2012                                                              MCA from KSOU, Mysore in 2013                                                                                                                                                  M.Phil from Dr. C.V. Raman University, Bilaspur in 2015                                   Pursuing Ph. D from Global Univerity',
		email: "bhabanisankar@niisgroup.org",
		experience: "25 years",
		teachingInterest:
			"C, C++, OS, DS,MIS, UNIX, Web Technology, Data Warehousing & Mining, Mobile Communication, Network Security",
		researchInterest: "Data Warehousing & Data Mining",
		publication: "17 published papers, 20 seminars & 1 patent",
		imageUrl: require("../assets/faculty/STAFF PHOTO/Bhabani Sankar Rath.JPG"),
	},

	{
		name: "Dr. Alekha Kumar Baliarsingh",
		designation: "Assistant Professor, MCA",
		qualification:
			"M.Sc, M.phil, M.tech(Comp. Science) and ph.D(Mathematics).",
		email: "alekhakumar@niisgroup.org",
		experience: "16 years",
		teachingInterest: "Discrete mathematics and structure, Ordinary Differential equation, Partial Differential equation, Operation Research, Numerical Analysis, Complex Analysis, Probability, Linear Algebra, Differential Geometry, Real Analysis.",
		researchInterest: "NA",
		publication: "4 Journals & 3 Seminars",
		imageUrl: require("../assets/faculty/STAFF PHOTO/ALEKHA KUMAR BALIARSINGH.jpg"),
	},
	{
		name: "Dr. Ratidev Samal",
		designation: "Associate Professor, MBA & Representative of IQAC, Research Committee, Grievance Handling Committee",
		qualification:"MBA from BPUT in 2008, M.Com & PGDIBO from IGNOU in 2012,    UGC NET in 2013, Ph. D from Berhampur University in 2016",
		email: "ratidev@niisgroup.org",
		experience: "14 Years",
		teachingInterest: "Services Marketing, Product & Brand Management, Retailing",
		researchInterest: "NA",
		publication:
			"13 research articles including UGC CARE & SCOPUS, 3 Books, 2 Edited Books, 57 seminars/workshops/FDPs Attended",
		imageUrl: require("../assets/faculty/STAFF PHOTO/RATIDEV SMAL.jpeg"),
	},

	{
		name: "Prof. Shrimoy Parichha",
		designation: "Assistant Professor, MBA",
		qualification:
			"MBA from Sambalpur University  in 2001, Pursuing Ph. D from FM University, Balasore",
		email: "shrimoy@niisgroup.org",
		experience: "21 years",
		teachingInterest:
			"C, C++, File Structure, Java, S/W Engineering, COA, CF, CCN",
		researchInterest: "Data Warehousing",
		publication: "N.A",
		imageUrl: require("../assets/faculty/STAFF PHOTO/Shrimoy Paricha.JPG"),
	},
	{
		name: "Prof. Soumyasudha Ojha",
		designation: "Assistant Professor, MCA",
		qualification:
			"MCA from BPUT, Rourkela,  in 2012                                                                              ",
		email: "soumyasudha@niisgroup.org",
		experience: "10 years",
		teachingInterest:
			"C, C++, File Structure, Java, S/W Engineering, COA, CF, CCN",
		researchInterest: "Data Warehousing",
		publication: "8 Seminars",
		imageUrl: require("../assets/faculty/STAFF PHOTO/soumyasudha Ojha.jpg"),
	},

	// {
	// 	name: "Dr. Bimal Kanta Nayak",
	// 	designation: "Associate Professor, MBA",
	// 	qualification:
	// 		"M.A. from Utkal University in 1995                                                                           Ph. D. from Utkal University in 2002		",
	// 	email: "bimalkanta@niisgroup.org",
	// 	experience: "26 years",
	// 	teachingInterest: "Social Work",
	// 	researchInterest: "Health & Social Issuses",
	// 	publication:
	// 		"1) International Journal                    - 15 nos.                                                      2) National Journal                           - 05 nos.                                                     3) Magazine                                      - 15 nos.                                                      4) Paper presented                           - 4 nos.                                                                                                   5) Seminar/Conference attended     - 17 nos.                                                     		",
	// 	imageUrl: require("../assets/faculty/STAFF PHOTO/BIMAL KANTA NAYAK.jpg"),
	// },
	{
		name: "Prof. Chinmay Kumar Rout",
		designation: "Assistant Professor, MCA & Examination coordinator, Anti Ragging Squad, SC & ST Commiittee, Hostel Committee",
		qualification:
			"MCA from BPUT, Rourkela,  in 2018                                                                                 		",
		email: "chinmay@niisgroup.org",
		experience: "4 years",
		teachingInterest: "C, C++, VB  & E-Commerce",
		researchInterest: "Computer Networking",
		publication: "1 book chapter & 7 seminars and FDP",
		imageUrl: require("../assets/faculty/All/Chinmay Kumar Rout.jpeg"),
	},
	{
		name: "Prof. Lipika Agasti",
		designation: "Assistant Professor, MCA & Anti ragging squad, Preventation of Sexual Harrassment.",
		qualification:
			"MCA from BPUT, Rourkela,  in 2016 , M. Tech from BPUT, Rourkela",
		email: "lipika@niisgroup.org",
		experience: "6 years",
		teachingInterest: "NA",
		researchInterest: "NA",
		publication: "7 seminars including FDPs",
		imageUrl: require("../assets/faculty/All/Lipika Agasti.JPG"),
	},
	{
		name: "Prof. Satyanarayan Behera",
		designation: " Assistant Professor (MBA)		",
		qualification:
			"MA(Economics) from Berhampur University in 1997, MPhil(Economics) from Berhampur University in 1999, MBA(HRM) from Pondicherry  University in 2021, PhD(Economics) Continuing at Berhampur University",
		email: "satya@niisgroup.org",
		experience: "22 years",
		teachingInterest: " Managerial Economics, Environmental Economics, Engineering Economics, Development Economics",
		researchInterest: "NA",
		publication: "2 papers published in Journals, 55 Seminars, FDPs, short term courses attended		",
		imageUrl: require("../assets/faculty/All/Satyanarayan Behera.JPG"),
	},

	{
		name: "Prof. Vineta Debayani Mishra",
		designation: "Asst. Professor, MCA & Examination-in-Charge, Anti Ragging committee, Sexual Harrasment Committee, Grievance Handling Committee",
		qualification:
			"MCA from BPUT, Rourkela,  in 2008                                                                                   M. Tech from BPUT, Rourkela in 2010                                                                 Pursuing Ph. D from CV Raman Global University",
		email: "vinita@niisgroup.org",
		experience: "13 years",
		teachingInterest: "NA",
		researchInterest: "NA",
		publication: "3 book chapters & 10 seminars including FDPs",
		imageUrl: require("../assets/faculty/STAFF PHOTO/VINITA DEBYANI MISHRA.jpeg"),
	},
	{
		name: "Prof. Rasmita Panda",
		designation: "Asst. Professor, MBA & Representative of Prevention of Sexual Harrassment Committee, Cultural Committee",
		qualification: "M. Com from Berhempur University in 2014.		",
		email: "rashmita@niisgroup.org",
		experience: "7 years",
		teachingInterest: "NA",
		researchInterest: "NA",
		publication: "7 seminars including FDPs",
		imageUrl: require("../assets/faculty/All/Rasmita Panda.JPG"),
	},

	{
		name: "Prof. Satyajit Pattanaik",
		designation: "Asst. Professor, MBA & Hostel Supdt, Representative of Sports Committee, Anti Ragging Squad, Hostel Committee. ",
		qualification: "MBA from BPUT, Rourkela in 2007 		",
		email: "satyajit@niisgroup.org",
		experience: "15 years ",
		teachingInterest: "Marketing, Business Practices, PMOB",
		researchInterest: "Retail Marketing",
		publication: "1 journal, 1 book chapter & 9 seminars including FDPs",
		imageUrl: require("../assets/faculty/All/Satyajit Pattanaik.JPG"),
	},
	{
		name: "Dr. Ajit Narayan Mohanty",
		designation: "Professor,MBA & Representative of Anti Ragging, Member of Shortage of Aattendance Committee (Health Issues Committee of students)",
		qualification:
			"MBA from  Utkal University in  1991                                                                                                             Ph. D from  Utkal University in 2015                     		",
		email: "ajitnarayan@niisgroup.org",
		experience: "28 Years",
		teachingInterest: "MM, IB, CB, SM, P&BM, B2B Marketing",
		researchInterest: "NA",
		publication:
			'Published 13 research article/papers in different National and International Journals & attended 30+ Seminars".		',
		imageUrl: require("../assets/faculty/STAFF PHOTO/Ajit Narayan Mohanty.JPG"),
	},
	{
		name: "Prof. Kshitish Kumar Mahapatra",
		designation: "Assistant Professor, MBA & Representative of Sports Committee",
		qualification:
			"MBA from BPUT, Rourkela in 2008                                                                         M. Com from FM University in 2011                                                                        LLB from Berhempur University in 2015		",
		email: "kshitish@niisgroup.org",
		experience: "14 years",
		teachingInterest: "Finance",
		researchInterest: "Ratio Analysis",
		publication: "1 Journal & 10 seminars including FDPs",
		imageUrl: require("../assets/faculty/All/Kshitish Kumar Mahapatra.JPG"),
	},
	{
		name: "Prof. Debolina Senapaty",
		designation: "Asst. Professor, MBA & Representative of Sexual Harrasment Committee",
		qualification:
			"MBA from Utkal University in 2015                                                                         Continung Ph. D from Utkal University		",
		email: "debolina@niisgroup.org",
		experience: "6 years",
		teachingInterest: "NA",
		researchInterest: "NA",
		publication:
			"3 Journals",
		imageUrl: require("../assets/faculty/STAFF PHOTO/DEBOLINA SENAPATHY.jpeg"),
	},
	{
		name: "Prof. Abinash Patnaik",
		designation: "Asst. Professor, MBA ",
		qualification: "MBA from BPUT, Rourkela in 2018 		",
		email: "abhinash@niisgroup.org",
		experience: "4 years",
		teachingInterest: "NA",
		researchInterest: "NA",
		publication: "7 seminars including FDPs",
		imageUrl: require("../assets/faculty/All/Abinash Patnaik.JPG"),
	},

	{
		name: "Dr. N. Sugundan",
		designation: "Asst. Professor, MBA & Representative of IQAC, Committee, Anti Ragging Committee",
		qualification:
			"B. Tech from Anna University  in 2007                                                                              MBA from Pondichery University in 2014                                                                                                 Ph. D. from Pondichery Central University  in 2017, PG Diploma in Industrial Psychology, PG Diploma in Statistic & Research Methodology, PG Diploma in International Business   ",
		email: "nsugundan@niisgrouup.org",
		experience: "15 years",
		teachingInterest: "NA",
		researchInterest: "NA",
		publication: "Scopus Indexed Journals, ABDC Journals and Published a Book.  ",
		imageUrl: require("../assets/faculty/All/N. Sugundan.JPG"),
	},
	{
		name: "Prof. Santosh Kumar Sahu",
		designation: "Assistant Professor, MCA & Representative of Anti Ragging , RTI Cell ",
		qualification: "MCA from BPUT, Rourkela in 2011 		",
		email: "santosh@niisgroup.org",
		experience: "11 years",
		teachingInterest: "DBMS, SE, OOAD & ECOM",
		researchInterest: "Network Security",
		publication: "7 seminars including FDPs",
		imageUrl: require("../assets/faculty/STAFF PHOTO/SANTOSH KUMAR SAHU.png"),
	},
	{
		name: "Prof. Nihar Ranjan Panda",
		designation: "Assistant Professor, MCA",
		qualification:
			"MCA from IGNOU, New Delhi in 2008                                                                             M. Tech from Berhampur University, Odisha  in 2011                                                                                		",
		email: "nihar@niisgroup.org",
		experience: "14 years",
		teachingInterest: "C, C++, DA, DS, OS, Networking & SE",
		researchInterest: "Networking",
		publication: "1 Book Chapter & 7 Seminars including FDPs",
		imageUrl: require("../assets/faculty/All/Nihar Ranjan Panda.JPG"),
	},
	{
		name: "Prof. Sradhanjali Nayak",
		designation: "Assistant Professor, MCA & Representative of Sexual Harrasment Committee, Anti Ragging Squad, ",
		qualification:
			"B. Tech  from BPUT, Rourkela in 2006                                                           M. Tech from BPUT, Rourkela in 2009                                                   GATE Qualified.                                                                                                                       Ph. D. submitted in Utkal University in 2022.		",
		email: "sradha@niisgroup.org",
		experience: "10 years",
		teachingInterest: "AI, MAP, Comp. Desgn, Theory of Comp.",
		researchInterest: "Pattern recognition, Image processing, Optical character Recognition, Classification algorithm, Design and Analysis Algorithm, Artificial Intelligence, Data mining (clustering and Diclustering), ",
		publication:
			"5 Journals & 10+ Seminars including FDPs",
		imageUrl: require("../assets/faculty/All/Sradhanjali Nayak.JPG"),
	},
	{
		name: "Prof. Suryakanta Mahapatra",
		designation: "Asst. Professor, MCA & Representative of Anti Ragging , Grievance Handling Comiittee",
		qualification:
			"MCA from Osmania University in 1994                                                                  M. Tech from Berhampur University, Odisha  in 2010                                                                                ",
		email: "suryakant@niisgroup.org",
		experience: "22 years",
		teachingInterest: "JAVA,OS,C,C++,DS & VB",
		researchInterest: "Adhoc Networking",
		publication: "1 Book Chapter & 8 Seminars including FDPs",
		imageUrl: require("../assets/faculty/All/Suryakanta Mohapatra.JPG"),
	},
	{
		name: "Prof. Debasish Pradhan",
		designation: "Asst.Professor (MCA)",
		qualification:
			"B. Tech from BPUT, Odisha in 2010                                                                                             M. Tech from BPUT, Odisha in  2013                                                                                  Pursuing Ph. D. from CV Raman Global University.   		",
		email: "debashish@niisgroup.org",
		experience: "13 years",
		teachingInterest: " C programming, JAVA, Python,  Software Engineering,  Machine Learning, Data Structure, Soft Computing, Operating System.		",
		researchInterest: "NA",
		publication: " 3 Jounal, 3 Conference	",
		imageUrl: require("../assets/faculty/All/Debasish Pradhan.JPG"),
	},
	// {
	// 	name: "Prof. Ajit Kumar Sabat",
	// 	designation: "Asst. Professor, MBA",
	// 	qualification:
	// 		"M. Sc. from  Ravenshaw University in  2016                                                                                                                   M.Phil from  Ravenshaw University in 2017                                                                                                                 PGDAS from IGNOU, New Delhi in 2018                           		",
	// 	email: "ajit@niisgroup.org",
	// 	experience: "5 years",
	// 	teachingInterest: "NA",
	// 	researchInterest: "NA",
	// 	publication: "NA",
	// 	imageUrl: require("../assets/faculty/All/Ajit Kumar Sabat.JPG"),
	// },
	{
		name: "Prof. Swarup Pradhan",
		designation: "Asst. Professor, MBA & Representative of Assist in Placement Cell",
		qualification:
			"M.A. in Economics from Central University of Odisa in 2013                                                      MBA from Raenshaw University in 2015                                                                                                              Continuing Ph. D. from Ravenshaw University.                 		",
		email: "swarup@niisgrouup.org",
		experience: "7 years",
		teachingInterest: "NA",
		researchInterest: "NA",
		publication:
			"3 Research Articles, 2 Case Studies & 11 Seminars including FDPs",
		imageUrl: require("../assets/faculty/STAFF PHOTO/SWARUP PRADHAN.png"),
	},

	{
		name: "Prof. Pravakar Mishra",
		designation: "Assistant Professor, MCA & Representative of Anti Ragging, Sexual Harrasment",
		qualification:
			"MCA from Smbalpur University in 2005                                                                                  M. Tech from BPUT, Rourkela in 2010		",
		email: "pravakar@niisgroup.org",
		experience: "16 years",
		teachingInterest: "JAVA, C, C++, DS, PHP & Cyber Security",
		researchInterest: "Real Time OS",
		publication: "1 International Journal & 15 Seminars including FDPs",
		imageUrl: require("../assets/faculty/All/Pravakar Mishra.jpeg"),
	},
	// {
	// 	name: "Prof. Madhusmita Das",
	// 	designation: "Asst. Professor, MCA ",
	// 	qualification:
	// 		"MCA from Sambalpur University in 2006                                                                                     M. Tech from BPUT, Rourkela in 2011		",
	// 	email: "madhusmita@niisgroup.org",
	// 	experience: "14 years",
	// 	teachingInterest: "NA",
	// 	researchInterest: "NA",
	// 	publication: "NA",
	// 	imageUrl: require("../assets/faculty/STAFF PHOTO/MADHUSMITA DAS.jpeg"),
	// },

	{
		name: "Prof. Uttam Kumar Giri",
		designation: "Asst. Professor,MBA, M. Com ",
		qualification:
			"MBA From FM University,  Balasore in 2016                                                                                        M. Com from  FM (Autonomus) College, Balasore on 2019                         		",
		email: "uttam@niisgroup.org",
		experience: "6 years",
		teachingInterest: "NA",
		researchInterest: "NA",
		publication: "1 Journal & 10+ Seminars including FDPs",
		imageUrl: require("../assets/faculty/STAFF PHOTO/Uttam Kumar Giri.JPG"),
	},

	{
		name: "Prof, Sumita Dhar",
		designation: "Asst. Professor, MCA & Representative of Sexual Harrasment Committee, Anti Ragging ",
		qualification:
			"B. Tech from WBUT, West Bengal in 2008                                                                      M. Tech from BPUT, Rourkela  in 2015                                                                                                                                                                                                         Ph. D course work completed  from North Orissa Univerity in 2022		",
		email: "sumita@niisgroup.org",
		experience: "6 years",
		teachingInterest: "Artificial Intelligence, Datamining ,Software Engineering",
		researchInterest: "NA",
		publication: "1 Journal & 8 Seminars including SDPs",
		imageUrl: require("../assets/faculty/STAFF PHOTO/Sumita Dhar.JPG"),
	},
	{
		name: "Prof. Gyana Ranjan Biswal",
		designation: "Assistant Professor, MBA",
		qualification: "MBA From BPUT, Rourkela in 2008		",
		email: "gyana@niisgroup.org",
		experience: "12 years",
		teachingInterest: "HR, Marketing, CS",
		researchInterest: "HRM, Marketing Research",
		publication:
			"2 Journals & 10 Seminars including FDPs 		",
		imageUrl: require("../assets/faculty/STAFF PHOTO/GYANA RANJAN BISWAL.jpeg"),
	},
	{
		name: "Prof. Indrajeet Das",
		designation: "Asst. Professor in MBA & Representative of Anti ragging squad, SC & ST Committee",
		qualification:
			"M.Com from FM University  in  2003                                                                       M. Phil from Utkal University in 2009		",
		email: "indrajeet@niisgroup.org",
		experience: "18 years",
		teachingInterest: "Accounts & Finance",
		researchInterest:
			"Financial Accounting, Management Accounting, Financial Management and Cost Management Accounting and QTM.",
		publication: "6 Seminars including FDP",
		imageUrl: require("../assets/faculty/All/Indrajeet Das.jpeg"),
	},
	// {
	// 	name: "Dr. Megha Sharma.",
	// 	designation: "Associate Professor, MBA",
	// 	qualification:
	// 		"                                                                                                                                                                                                                                                                            MA from University of Rajasthan in 2006                                       MBA from  Vinyka University in 2008                                                                                                                                                                                                                                                                                                                         Ph. D from University of Rajasthan in 2012                                          ",
	// 	email: "megha@niisgroup.org",
	// 	experience: "12 years",
	// 	teachingInterest: "JAVA, C, C++, DS, PHP & Cyber Security",
	// 	researchInterest: "Real Time OS",
	// 	publication: "NA",
	// 	imageUrl: require("../assets/faculty/STAFF PHOTO/Megha Sharma.jpg"),
	// },
	{
		name:"Mr. Sthitaprajna Debadatta Samal",
		designation:"Asst. Professor in MBA & Representative of Anti Ragging Squad, NSS Coordinator",
		email:"spdsamal@niisgroup.org",
		experience: "14 years",
		qualification:"MBA ",
		teachingInterest:"Marketing",
		researchInterest:"Managerial Ecconomics, Development Economics, Agricultural Economics and Banking & Insurance.",
		publication:"1 Book Chapter & 10+ Seminars including FDPs",
		imageUrl: require("../assets/faculty/STAFF PHOTO/Mr. Sthitaprajna Debadatta Samal.png"),
	},
	{
		name:"Ms. Ankita Jena",
		designation:"Asst. Professor, MBA & Representative of Sexual Harrasment Cell, Girls Common Room Incharge.",
		email:"ankita@niisgroup.org",
		qualification:"MBA ",
		teachingInterest:"HR",
		publication:"1 Journal & 1 Seminar",
		imageUrl: require("../assets/faculty/STAFF PHOTO/Ms. Ankita Jena.png"),
	},
	{
		name:"Mr. Farnadish Mishra",
		email:"farndish@niisgroup.org",
		qualification:"M. Tech in CSE in 2018",
		designation:"Associate Professor, MCA",
		teachingInterest:"CSIT",
		imageUrl: require("../assets/faculty/STAFF PHOTO/Mr. Farndish Mishra.png"),
	},
	{
		name:"Mr. Sudeep Kumar Gochhayat",
		email:"sudeepkumar@niisgroup.org",
		qualification:"BTech, MTech",
		designation:"Associate Professor, MCA",
		experience:"6 years",
		teachingInterest:"CSIT",
		publication:"4 Journals & 3 Seminars including FDPs",
		imageUrl: require("../assets/faculty/STAFF PHOTO/Mr. Sudeep Kumar Gochhayat.png"),
	},
	{
		name:"Mr. Aira Kharvel Parida",
		email:"akparida@niisgroup.org",
		qualification:"M.Tech, PhD (Continue)",
		experience:"12 years",
		designation:"Associate Professor, MCA",
		teachingInterest:"CSIT",
		publication:"1 Journal & 5 Seminar including FDPs",
		imageUrl: require("../assets/faculty/STAFF PHOTO/Mr. Aira Kharvel Parida.png"),
	},
	{
		name:"Ms. Swarnamayee Dash",
		email:"swarnamayee@niisgroup.org",
		qualification:"MCA",
		designation:"Associate Professor, MCA",
		teachingInterest:"CSIT",
		imageUrl: require("../assets/faculty/STAFF PHOTO/Ms. Swarnamayee Dash.png"),
	},
	{
		name:"Mr. Pramod Kumar Pradhan ",
		email:"pkpradhan@niisgroup.org",
		qualification:"BTech, MTech",
		designation:"Associate Professor, MCA",
		teachingInterest:"CSIT",
		imageUrl: require("../assets/faculty/STAFF PHOTO/Mr. Pramod Kumar Pradhan.png"),
	},
	// {
	// 	name: "Prof. Debashis Mishra",
	// 	designation: "Asst. Professor in MBA & Academic Co-ordinator",
	// 	qualification:
	// 		"M.Tech.(Mechanical Engineering)",
	// 	experience: "7 years",
	// 	publication:
	// 		"18 Journals and Articles & 3 Seminars",
	// 	imageUrl: require("../assets/faculty/All/Indrajeet Das.jpeg"),
	// },
	// {
	// 	name: "Prof. Suchitra Nathsharma",
	// 	designation: "Asst. Professor in MCA",
	// 	qualification:
	// 		"MCA, M.Tech in Computer Science",
	// 	experience: "8 years",
	// 	imageUrl: require("../assets/faculty/All/Indrajeet Das.jpeg"),
	// },
	// {
	// 	name: "Dr. Subhashree Panda",
	// 	designation: "Associate Professor, MCA",
	// 	qualification:
	// 		"Ph.D in Mathmatics, Centurian University, Bhubaneswar",
	// 	experience: "5 years",
	//  publication:
	// 		"7 Journals & 4 Conferences",
	// 	imageUrl: require("../assets/faculty/All/Indrajeet Das.jpeg"),
	// },


];

import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import { AiFillFilePdf } from "react-icons/ai";
import CustomIcon from "../CustomIcon/CustomIcon";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";

const CourseInfoResponsive = () => {
  const [currentBreakPoint, setCurrentBreakPoint] = useState("");

  const descriptionClasses = classNames("mb-4 text-center text-text-color", {
    "font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
  });

  useEffect(() => {
    bsBreakpoints.init();
    setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

    const handleBreakPoint = (event) => {
      setCurrentBreakPoint(event.detail);
    };

    window.addEventListener("new.bs.breakpoint", handleBreakPoint);

    return () => {
      window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
    };
  }, []);

  return (
    <PageSection bgClass="background-color">
      <ResponsivePageHeader centered={true} title="Courses" />
      <p className={descriptionClasses}>
        We live at the intersection of teaching and doing, where learning is
        more than the transmission of knowledge, it is an act of collaboration
        and practice. Under the mentorship of our distinguished faculty, you’ll
        go beyond gathering information to not just learning about your field,
        but also to learn your own way of thinking. Through lectures, projects,
        independent studies, discussions, events, internships and more, you’ll
        leave NIIS with the intellectual skills of perception, analysis,
        interpretation and expression.
      </p>
      <a
        href={process.env.PUBLIC_URL + "/RESEARCHPOLICY.pdf"}
        className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
        target="_blank"
        rel="noopener noreferrer"
      >
        <CustomIcon currentIcon={AiFillFilePdf} size="md" />
        <span className="ml-2">DOWNLOAD RESEARCH POLICY</span>
      </a>
      <br />
      <a
        href={
          process.env.PUBLIC_URL +
          "/Academic Calendar for PG Programmes, 2024-25.pdf"
        }
        className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
        target="_blank"
        rel="noopener noreferrer"
      >
        <CustomIcon currentIcon={AiFillFilePdf} size="md" />
        <span className="ml-2">
          DOWNLOAD Academic Calendar for PG Programmes, 2024-25
        </span>
      </a>
      <br />
      <a
        href={process.env.PUBLIC_URL + "/Fee Refund Policy.pdf"}
        className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
        target="_blank"
        rel="noopener noreferrer"
      >
        <CustomIcon currentIcon={AiFillFilePdf} size="md" />
        <span className="ml-2">DOWNLOAD Fee Refund Policy</span>
      </a>
      <br />
      <a
        href={
          process.env.PUBLIC_URL +
          "/Fees Details of MBA & MCA Govt Order Regarding Fee Fixation.pdf"
        }
        className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
        target="_blank"
        rel="noopener noreferrer"
      >
        <CustomIcon currentIcon={AiFillFilePdf} size="md" />
        <span className="ml-2">
          DOWNLOAD Fees Details of MBA & MCA Govt Order Regarding Fee Fixation
        </span>
      </a>
    </PageSection>
  );
};

export default CourseInfoResponsive;

import React from "react";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import HeroSection from "../../components/HeroSection/HeroSection";
import HeroCaption from "../../components/HeroCaption/HeroCaption";
import CourseInfoResponsive from "../../components/CourseInfoResponsive/CourseInfoResponsive";
import FacultyResponsive from "../../components/FacultyResponsive/FacultyResponsive";
import CoursesResponsive from "../../components/CoursesResponsive/CoursesResponsive";
import PageSection from "../../components/PageSection/PageSection";
import ResponsivePageHeader from "../../components/ResponsivePageHeader/ResponsivePageHeader";

const CoursesPage = () => {
  return (
    <PageWrapper className="mt-n6">
      <HeroSection className="bg-page-background-7">
        <HeroCaption
          title="Courses"
          position="bottom-left"
          breadcrumb={true}
          breadcrumbPrefix="Home"
        />
      </HeroSection>
      <CourseInfoResponsive />
      {/* <FacultyResponsive /> */}
      <CoursesResponsive />
      <PageSection bgClass="white">
        <ResponsivePageHeader centered={true} title="Study in India" />
        <p>
          We are encouraging students of within the state as well as outside for
          Management and IT programmes in Post Graduate level. Accordingly we
          have flagged the admission form along with necessary guidelines. We
          have already setup city offices at different surrounding states like
          Kolkata, Tripura, Chhatisgarh and Jharkhand. Our management has also
          been agreed to setup international offices in different countries
          particularly South Africa, Kenia, Uganda, Singapore, Indonesia,
          Srilanka and other south east Asian countries shortly.
        </p>
      </PageSection>
    </PageWrapper>
  );
};

export default CoursesPage;

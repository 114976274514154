
import React from "react";
import PageHeader from "../../components/PageHeader/PageHeader";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import PageSection from "../../components/PageSection/PageSection";
import HeroSection from "../../components/HeroSection/HeroSection";
import HeroCaption from "../../components/HeroCaption/HeroCaption";
import HealthResponsive from "../../components/HealthResponsive/HealthResponsive";
import GuidelineContentResponsive from "../../components/GuidelineContentResponsive/GuidelineContentResponsive";
import RTIResponsive from "../../components/RTIResponsive/RTIResponsive";

const RTIPage = () => {
  return (
    <PageWrapper className="mt-n6">
      <HeroSection className="bg-page-background-4">
        <HeroCaption
          title="RTI"
          position="bottom-left"
          breadcrumb={true}
          breadcrumbPrefix="Home"
        />
      </HeroSection>
      <RTIResponsive/>
    </PageWrapper>
  );
};

export default RTIPage;

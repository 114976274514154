export const chairmanData = {
  imageUrl: require("../assets/managing-trustee/chairman.png"),
  designation: "Founder Chairman",
  name: "Dr. Subhash Chandra Nayak",
  feedback: `When you first start your academic career, you should look for an institution that will
   take you on an exciting trip that will help you to grow and emerge as a leader. To better
   prepare you for future difficulties and disruptions, and to serve as a pillar in the progress
   of our great country. The NIIS Institute of Business Administration is the perfect place to begin your career because
   of its academic brilliance and philanthropic mission to develop global leaders.
   We are committed to making a difference in professional and higher education in India,
   and we are taking steps to develop a model that recognises and meets the
   requirements of students.`,
};

export const principalData = {
  imageUrl: require("../assets/faculty/STAFF PHOTO/Rajesh Sain.JPG"),
  designation: "Principal",
  name: "Dr. Rajesh Sain",
  feedback: `The roots of education are bitter, but the fruit is sweet” as stated by Aristotle that the
				outcome of education is always better than its input. So, students need to give their rigorous
				effort to get the better success in future. The contemporary, rigorous and crafted curriculum
				of different programs studied with utmost care by dedicated academicians to equip graduates
				with the skills, abilities and knowledge that will enable them to take up challenging positions
				in the corporate world or to become successful entrepreneurs. The values inculcated in
				students are impeccable and make the students socially responsible. Our endeavour is to
				make students to explore how their study can make a meaningful impact in society. <br><br>
				
				A fully immersive education imparted with a national and global perspective to make our
				students become emerging leaders who will improve and transform organizations even in
				critical sectors of society. We have signed 34MOUs with different corporate houses and
				educational institutions for extended learning, internship and placements. The placement
				teams dedicatedly working with the support of Industries to create opportunities for students
				to strengthen their career and have ample perceptive to serve in different areas of learning.
				Along with the above we are working hard to implement the NEP-2020 in our institution
				especially in the area of academic bank of credit, Indian Knowledge System, Universal
				Human Values and ethics etc. for developing value based transformational leaders who can
				contribute to the economic growth and social development of our Nation. <br><br>
				
				The institution has built up an impressive infrastructure which includes a serene campus with
				gardens and lawns, a well-stocked library, state of the art computer labs, modern Hostel
				facilities, Open GIM and volleyball courts for Sports and recreational activities. Amenities
				combined with competent, talented and dedicated faculty who strive to reach out, educate,
				mentor, guide and champion all those who choose to be part of NIIS. <br><br>
				
				I believe you will have an enjoyable learning experience.`,
};

export const jmTrusteeData = {
  imageUrl: require("../assets/managing-trustee/trustee2.png"),
  designation: "Joint Managing Trustee",
  name: "Prof. Smrutisudha Nayak",
  feedback: `We also provide you with an exceptional opportunity to push yourself to new heights while also creating an environment that fosters interactive learning and life skills. If any of this rings true for you, and you're dedicated to achieving success in your chosen industry, we look forward to meeting you on our bustling campus!`,
};

export const memberData = [
  {
    imageUrl: require("../assets/faculty/STAFF PHOTO/Satyapira Choudhury.jpg"),
    designation: "Faculty Nominee",
    name: "Satyapira Choudhury",
    feedback: `"Education is not just about learning, it's about empowering the next generation to make a difference in the world"`,
  },
  {
    imageUrl: require("../assets/faculty/STAFF PHOTO/Bhabani Sankar Rath.JPG"),
    designation: "Faculty Nominee",
    name: "Prof. Bhabani Shankar Rath",
    feedback: `"At NIIS, we believe in fostering an environment that encourages creativity, innovation, and excellence.""`,
  },
  {
    imageUrl: require("../assets/faculty/STAFF PHOTO/D. K. Pattanaik.JPG"),
    designation: "Faculty Nominee",
    name: "Dr. Dusmanta Kumar Pattanaik",
    feedback: `"Our goal is to provide our students with a holistic education that prepares them for both personal and professional success."`,
  },
  {
    imageUrl: require("../assets/faculty/STAFF PHOTO/Rajesh Sain.JPG"),
    designation: "Faculty Nominee",
    name: "Dr. Rajesh Sain",
    feedback: `"As members of the Board of Governors, we are committed to ensuring that NIIS continues to be a leading institution in the field of technical education."`,
  },
];

import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import { AiFillFilePdf } from "react-icons/ai";
import CustomIcon from "../CustomIcon/CustomIcon";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";

const HealthResponsive = () => {
  const [currentBreakPoint, setCurrentBreakPoint] = useState("");

  useEffect(() => {
    bsBreakpoints.init();
    setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

    const handleBreakPoint = (event) => {
      setCurrentBreakPoint(event.detail);
    };

    window.addEventListener("new.bs.breakpoint", handleBreakPoint);

    return () => {
      window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
    };
  }, []);

  const descriptionClasses = classNames("mb-4 text-center text-text-color", {
    "font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
  });

  return (
    <PageSection bgClass="background-color">
      <ResponsivePageHeader centered={true} title="Health Facilities" />
      <p className={descriptionClasses}>
        NIBA provided health facilities to the students as well as staff members
        in the nearby hospitals for basic treatments. A special vehicle is
        assigned with a driver for 24x7x52Hours for the same. In case of
        students, if any serious ailments are noticed by the Doctors to be
        communicated to the parents for better treatment. All office bearers are
        availing health insurance facilities of Rs. 2 lakhs individually. All
        eligible employees are enjoying the facility of ESI as per norms.
        Institute also provides medical leaves as and when needed by the
        employees. Maternity leave of 90 days is given for eligible staff.
        Pregnant ladies and lactated mothers are given flexible timing of work
        as per their choice. <br /> Institute has tied-up with AMRI Hospital for
        providing medical services to employees at subsidized cost. Besides
        this, free medical camp is organized for staff.
      </p>
      <a
        href={
          process.env.PUBLIC_URL +
          "/Health Facilities-3 Mou with Sparsh Hospital.pdf"
        }
        className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
        target="_blank"
        rel="noopener noreferrer"
      >
        <CustomIcon currentIcon={AiFillFilePdf} size="md" />
        <span className="ml-2">Mou with Sparsh Hospital</span>
      </a>
      <br />
      <a
        href={
          process.env.PUBLIC_URL +
          "/Health Facilities -2 Manipal Hospital MOU.pdf"
        }
        className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
        target="_blank"
        rel="noopener noreferrer"
      >
        <CustomIcon currentIcon={AiFillFilePdf} size="md" />
        <span className="ml-2">Mou with Manipal Hospital</span>
      </a>
    </PageSection>
  );
};

export default HealthResponsive;
